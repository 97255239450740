import React, { useState, useEffect } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import rainpe from "../../assets/images/rainpe.png";
import Button from '@mui/material/Button';
import { redirect } from "../Api/Api";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export default function Another() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleResize = () => {
    if (window.innerWidth > 768) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="nav">
      <div className="tophalf">
        <Link to="/" className="logo-link">
          <img className="logo" src={rainpe} alt="something" />
        </Link>

        <div className="menu-container">
          <button className="menu-toggle" onClick={toggleMenu}>
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </button>

          <ul className={`bottomhalf ${isMenuOpen ? "open" : ""}`}>
            <li className="list">
              <Link className="anc" to="/" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li className="list">
              <Link className="anc" to="/about" onClick={toggleMenu}>
                About Us
              </Link>
            </li>
            <li className="list">
              <Link className="anc" to="/limited-partnerships" onClick={toggleMenu}>
                Limited Partnerships
              </Link>
            </li>
            <li className="list">
              <Link className="anc" to="/insights" onClick={toggleMenu}>
                Insights
              </Link>
            </li>
            <li className="list">
              <Link className="anc" to="/raisefunding" onClick={toggleMenu}>
                Raise Funding
              </Link>
            </li>
            <li className="list">
              <Link className="anc" to="/contactus" onClick={toggleMenu}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <Link to={redirect} className="login-button">
          <Button variant="contained" color="primary">
            Login
          </Button>
        </Link>
      </div>
    </nav>
  );
}
