import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import banking from '../../assets/images/banking.png';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Img, ButtonWrapper, ArrowForward,
  ArrowRight,
} from '../../components/herosection/elements.jsx';
import { Button } from '../../components/button/button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import i1 from '../../assets/images/i1.jpeg';
import main from '../../assets/images/main.png';
import b1 from '../../assets/images/b1.webp';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import React, { useState } from "react";
import Navbar from '../../components/navbar/index.jsx';
import Footer from '../../components/Footer/Footer.js';
import { useEffect } from 'react';



export default function BankingAlert() {
  const navigate = useNavigate()
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover)
  }

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Navbar />

      <Box sx={{ backgroundColor: "#EEF5F9", display: "flex", height: "30rem" }}>
        <Box sx={{ padding: "5rem 1rem 1rem 3rem" }}>
          <Typography variant='h4' sx={{ fontWeight: 600, textAlign:'center' }}>Official Bank Accounts of RainPe Direct</Typography>
          <Typography sx={{ textAlign: "left" }}>At RainPe Direct, the security of your investments is paramount. We are committed to ensuring that every transaction you make is safe, secure, and transparent. To this end, we wish to inform all our esteemed investors of our official banking details.</Typography>
        </Box>
        <img src={banking} style={{ height: "27rem" }} />
      </Box>

      <Box>
        <Box sx={{ flexGrow: 1 }} >
          <Grid container spacing={2} columns={16}>
            <Grid item xs={4}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      Live From Space
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      Mac Miller
                    </Typography>
                  </CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                  </Box>
                </Box>
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  img src={i1} alt='i1'

                />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      Live From Space
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      Mac Miller
                    </Typography>
                  </CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                  </Box>
                </Box>
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  img src={i1} alt='i1'

                />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      Live From Space
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      Mac Miller
                    </Typography>
                  </CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                  </Box>
                </Box>
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  img src={i1} alt='i1'

                />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      Live From Space
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      Mac Miller
                    </Typography>
                  </CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                  </Box>
                </Box>
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  img src={i1} alt='i1'

                />
              </Card>
            </Grid>
          </Grid><br /><br /><br />

          {/* <ButtonWrapper >
                                    <Link to='/price' style={{textDecoration: 'none'}}>
                                        <Button 
                                            onClick= {()=>{navigate('/price')}}
                                            onMouseEnter={onHover}
                                            onMouseLeave={onHover}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact={true}
                                            offset={-80}
                                            primary={1}
                                            dark={0}
                                            dark2={0}
                                        >
                                            Load More{hover ? <ArrowForward /> : <ArrowRight/>}
                                        </Button>
                                    </Link>
                                </ButtonWrapper> */}

          <br /><br />
        </Box>
      </Box>

      <Typography variant='h4' sx={{ fontWeight: 600, padding: "4rem 0rem 0rem 20rem" }}>Beware of Fraudulent Activities!</Typography>
      <Box sx={{ margin: "1rem 1rem 1rem 1rem" }}>
        <Typography sx={{ margin: "1rem 0rem 1rem 0rem", textAlign: "justify" }}>It has come to our attention that some investors have been approached by individuals misrepresenting UnlistedZone, directing them to transfer funds to unauthorized bank accounts. Please be vigilant and confirm that you are depositing your investment into one of the official accounts listed above. Any request to transfer funds to any account not listed here should be considered fraudulent.</Typography>
        <Typography sx={{ margin: "1rem 0rem 1rem 0rem", textAlign: "justify", fontWeight: 600 }}>To safeguard your interests, we urge you to: <br />Always verify:</Typography>
        <Typography sx={{ margin: "1rem 0rem 1rem 0rem", textAlign: "justify" }}> Before making any transactions, double-check the account details against the information provided here.</Typography>
        <Typography sx={{ margin: "1rem 0rem 1rem 0rem", textAlign: "justify" }}> Keep us informed: If you encounter any suspicious activity or receive requests to transfer funds to any other account, please report it immediately to UnlistedZone via the contact details	below.</Typography>
        <Typography sx={{ margin: "1rem 0rem 1rem 0rem", textAlign: "justify" }}> Stay alert: Be cautious of unsolicited communications asking for financial transactions, even if they appear to be from UnlistedZone.</Typography>
        <Typography sx={{ margin: "1rem 0rem 1rem 0rem", textAlign: "justify" }}> We value your trust and investment with us and are dedicated to ensuring that your journey in the world of unlisted, Pre-IPO, and delisted shares is not just profitable but also secure.</Typography>
      </Box>
      <br />
      <br />
      <Footer />
    </>
  )
}