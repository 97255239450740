import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Button from '@mui/material/Button';
import './MainBody.css';
import speaker from './MainPageContent/speaker.png';
import Typography from '@mui/material/Typography';
import BodyInside from './MainPageContent/BodyInside';
import BodyTwo from './MainPageContent/BodyTwo';
import EndPage from './MainPageContent/EndPage';
import TablePage from './MainPageContent/Table';
import Testimonial from './MainPageContent/Testimonial';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from "react";
import Container from '@mui/material/Container';
import ShareCard from "../../components/cards/ShareCard.jsx";
import Grid from '@mui/material/Unstable_Grid2';
import main from '../../assets/images/main-trade.png'

 import bulb from '../../assets/images/banner.jpg'
 import vision from "../../assets/images/fund (1).webp";
import { useState } from "react";
import {api,imageApi} from "../../components/Api/Api.jsx";
import {redirect} from "../../components/Api/Api.jsx";
import { position } from "jodit/esm/core/helpers/index.js";
import { Opacity } from "@mui/icons-material";
import Box from "@mui/material/Box";

const cardPerPage = 6;
export default function MainBody({idpoly,headings,images,url}) {
    const [data,setData] = useState(null);
    const [dataOnPage,setDataOnPage] = useState(null);
    const [search,setSearch] = useState("");
    const [pages,setPages] = useState(1);
    const lastIndex = cardPerPage * pages;
    const firstIndex = lastIndex - cardPerPage;


    const styles = {
        containermain: {
             width: '100%',
            height: '70vh',
          
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            //  marginTop:'-50px'
            
        },
    };

  

    const FetchData = async ()=>{
        try{
        await fetch(api)
        .then(res => res.json())
        .then(res => setData(res.detail.rows.slice(-8)))
        }
        catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        FetchData()
    },[])

    const handleChange = (e)=>{
        setSearch(e.target.value)
    }

    const filter = data !== null ? data.filter(f=>(f.name).toLowerCase().includes(search.toLowerCase())) : []

   

    return (
        <>
            <Navbar />
            {/* <div style={styles.containermain}> */}
            <div className="main1">
                <div className="main11">

                    <h1>
                    Your Go-To FinTech Partner 
                    </h1>
                  
                    <p>At Rain Pe, we believe in the power of small and medium-sized enterprises (SMEs) to drive economic growth and innovation. Our mission is to provide these businesses with the financial tools and support they need to succeed. As a leading fintech company, we specialize in offering customized loans, strategic investments, and expert private equity consultancy services. </p>
                </div>
                {/* <img src={vision} style={{ width: '35%' }} alt='articalimage' /> */}
                <img
    src={vision}
 
    alt="article image"
  />
            </div>
               
            
            <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "left", padding: "20px", marginLeft:"90px", marginTop:"-25px" }}>
              <Button
                variant="contained"
                color="primary"
                
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/about"
                >
                  View More
                </Link>
              </Button>
            </Box>
      
          </Box>
                
            {/* </div> */}
            <BodyInside />
            <div className="dotboxmainbody">
                <div style={{ textAlign: 'center', fontFamily: "Daikon-regular" }}>
                    <h1 >What We Do:</h1>
                
                </div>
                <div className="square">
                    <div className="left-line"></div>
                    <div className="dot top-dot"></div>
                    <div className="dot bottom-dot"></div>
                    <div className="number number-top" style={{ fontWeight: "bold" }}>01</div>
              
                    <div className="outer-circle"></div>
                    <div className="left-text" style={{ fontFamily: 'Daikon-regular' }} > Unlock the capital you need to grow your business with our flexible loan solutions designed specifically for SMEs.
                    </div>
                    <div className="center-text" style={{ fontFamily: 'Daikon-regular' }}>Tailored Loans for SMEs</div>
                </div>
                <br />

                <div className="square2">
                    <div className="left-line2"></div>
                    <div className="dot2 top-dot2"></div>
                    <div className="dot2 bottom-dot2"></div>
                    <div className="number2 number-top2" style={{ fontWeight: 'bold' }}>02</div>
                    <div className="outer-circle2"></div>
                    <div className="left-text2" style={{ fontFamily: 'Daikon-regular' }}> Bring your projects to life with our dedicated funding options, supporting everything from research and development to large-scale expansions
                    </div>
                    <div className="center-text2" style={{ fontFamily: 'Daikon-regular' }}>Strategic Project Funding</div>
                </div>

                <br />
                <div className="square">
                    <div className="left-line"></div>
                    <div className="dot top-dot"></div>
                    <div className="dot bottom-dot"></div>
                    <div className="number number-top" style={{ fontWeight: "bold" }}>03</div>
               
                    <div className="outer-circle"></div>
                    <div className="left-text" style={{ fontFamily: 'Daikon-regular' }}>  Gain access to strategic investment partners and grow your business without the burden of debt through our equity funding solutions.
                    </div>
                    <div className="center-text" style={{ fontFamily: 'Daikon-regular' }}>Equity Funding</div>
                </div>

                <br />
                <div className="square2">
                    <div className="left-line2"></div>
                    <div className="dot2 top-dot2"></div>
                    <div className="dot2 bottom-dot2"></div>
                    <div className="number2 number-top2" style={{ fontWeight: "bold" }}>04</div>
                    <div className="outer-circle2"></div>
                    <div className="left-text2" style={{ fontFamily: 'Daikon-regular' }}> Prepare for a successful Initial Public Offering with our comprehensive financial support and strategic advice.
                    </div>
                    <div className="center-text2" style={{ fontFamily: 'Daikon-regular' }}>IPO Funding</div>
                </div>
            </div> 
            <Testimonial />
            <EndPage />
            <Footer />
        </>
    )
}