import React from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import aboutus from "../../assets/images/aboutus.jpg";
import Container from '@mui/material/Container';
// import mission from "../../assets/images/mission.jpg";
import vision from "../../assets/images/vision1.png";
import ourvalues from "../../assets/images/ourvalues.jpeg";
import mission from "../../assets/images/miss.jpg";
import ceo from "../../assets/images/ceo.png";
import dummy from "../../assets/images/dummyuser.png";

import aboutus2 from "../../assets/images/aboutus2.jpeg";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './AboutUs.css';

export default function AboutUs(){
    const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

    return(
        <>
        <Navbar />
        <Box sx={{padding:"2rem 0rem 0rem 0rem"}}>
            <img style={{width: '100%',justifyContent:"center",margin: "auto",
    display: "block",height:'20rem'}} src={aboutus2} alt="aboutus"/>
        </Box>
        <Typography variant="h5" sx={{textAlign:"center",fontWeight:600,margin:"2rem 0rem 2rem 0rem",fontFamily: "Daikon-regular"}}>Royal Angel Investor Network Private Equity (RAIN PE)</Typography>
        <Box sx={{margin:"0.5rem 6rem 0.5rem 6rem",textAlign:"left",fontFamily: "Daikon-regular"}}>
        <Typography variant="p" sx={{fontFamily: "Daikon-regular"}} >Rain Pe is a forward-thinking fintech company dedicated to transforming the financial landscape for SMEs. Our team of experienced financial professionals combines deep industry knowledge with cutting-edge technology to deliver customized financial services. We believe in fostering a vibrant business ecosystem where innovation and entrepreneurship can flourish.</Typography>
        </Box>
        <div className="mediacover1">
        <img src={mission} style={{ width: '35%' }} alt='articalimage' />
                <div className="mediacover11">

                    <h1>
                       Our Mission
                    </h1>
                  
                    <p>Our mission is to empower SMEs by offering the financial tools and support necessary for sustained growth and success. We strive to create value for our clients through strategic financial solutions, expert advice, and a commitment to excellence.</p>
                </div>
             
            </div>
        <div className="mediacover1">
                <div className="mediacover11">

                    <h1>
                       Our Vision
                    </h1>
                  
                    <p>Our vision is to transform the financial landscape for SMEs, enabling them to overcome challenges, seize opportunities, and achieve their full potential. We aim to be a trusted partner in their journey, providing the financial resources and expertise they need to thrive. </p>
                </div>
                <img src={vision} style={{ width: '35%' }} alt='articalimage' />
            </div>
      
          {/* <div className="mediacover1">
          <img src={ourvalues} style={{ width: '35%' }} alt='articalimage' />
                <div className="mediacover11">

                    <h1>
                    Our Values
                    </h1>
                  
                    <div className='letsbuild11'>
                <CheckCircleIcon sx={{color: "green",padding:"1.1rem 0rem 0rem 0rem"}} />
                <p> &nbsp;We uphold the highest standards of integrity in all our dealings, ensuring transparency and trustworthiness.</p>
            </div>
             <div className='letsbuild11'>
                <CheckCircleIcon sx={{color: "green",padding:"1.1rem 0rem 0rem 0rem"}}/>
                <p> &nbsp;Our clients are at the heart of everything we do. We prioritize their needs and work tirelessly to exceed their expectations.</p>
            </div>
                </div>
             
            </div> */}
<div className="mediacover1">
  <img src={ourvalues} style={{ width: '35%' }} alt='articalimage' />
  <div className="mediacover11">
    <h1>Our Values</h1>
    <div className='letsbuild11'>
      <CheckCircleIcon sx={{ color: "green", padding: "1.1rem 0rem 0rem 0rem" }} />
      <p>Integrity: We uphold the highest standards of integrity in all our dealings, ensuring transparency and trustworthiness in our interactions with clients and partners.
</p>
    </div>
    <div className='letsbuild11'>
      <CheckCircleIcon sx={{ color: "green", padding: "1.1rem 0rem 0rem 0rem" }} />
      <p>Customer-Centricity Our clients are at the heart of everything we do. We prioritize their needs and work tirelessly to exceed their expectations, providing personalized financial solutions and support.</p>
    </div>
    <div className='letsbuild11'>
      <CheckCircleIcon sx={{ color: "green", padding: "1.1rem 0rem 0rem 0rem" }} />
      <p>Excellence We are committed to excellence in every aspect of our business. Our team continually strives to improve and innovate, ensuring we deliver the best possible outcomes for our clients.</p>
    </div>
  </div>
</div>

{/* <div className="mediacover">
        <div className="mediacover1">
          <div className="mediacover11">
            <h1>Our Vision</h1>
            <p>Our vision is to be the trusted financial partner for SMEs, enabling them to reach new heights of success. We strive to create a thriving business ecosystem where innovative ideas and entrepreneurial spirit can flourish.</p>
          </div>
          <img src={vision} style={{ width: '35%' }} alt='articalimage' />
        </div>
        <div className="mediacover1">
          <img src={ourvalues} style={{ width: '35%' }} alt='articalimage' />
          <div className="mediacover11">
            <h1>Our Values</h1>
            <div className='letsbuild11'>
              <CheckCircleIcon sx={{ color: "green", padding: "1.1rem 0rem 0rem 0rem" }} />
              <p>&nbsp;We uphold the highest standards of integrity in all our dealings, ensuring transparency and trustworthiness.</p>
            </div>
            <div className='letsbuild11'>
              <CheckCircleIcon sx={{ color: "green", padding: "1.1rem 0rem 0rem 0rem" }} />
              <p>&nbsp;Our clients are at the heart of everything we do. We prioritize their needs and work tirelessly to exceed their expectations.</p>
            </div>
          </div>
        </div>
      </div> */}






    {/* <Box sx={{ flexGrow: 1 }}>
        <Container>
      <Grid container spacing={2} columns={8}>
        <Grid xs={4}>
       
          
          <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image="/static/images/cards/contemplative-reptile.jpg"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    
        </Grid>
        <Grid xs={4}>
        <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image="/static/images/cards/contemplative-reptile.jpg"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    
        </Grid>
      </Grid>
      </Container>
    </Box>
  */}


          <div className='about'>
        <div className='about1'>
          <h1>Why Choose<br/> RainPe?</h1>
          <div className='about11'>
            <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} ml={7} >
              <span style={{ fontWeight: 600, fontSize: 'larger' }}>Expertise:</span>
              <p>&nbsp;Our team consists of seasoned financial professionals with extensive experience in the fintech and private equity sectors.</p>
            </Box>
          </div>
          <div className='about11'>
            <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} ml={7}>
              <span style={{ fontWeight: 600, fontSize: 'larger' }}>Innovation:</span>
              <p>&nbsp;We leverage cutting-edge technology to offer innovative financial solutions tailored to the needs of modern businesses.</p>
            </Box>
          </div>
          <div className='about11'>
            <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} ml={7}>
              <span style={{ fontWeight: 600, fontSize: 'larger' }}>Commitment:</span>
              <p>&nbsp;We are dedicated to supporting SMEs and contributing to their growth and success.<br/><br/> </p>
            </Box>
          </div>
        </div>
      </div>

        {/* <div className='about'>
        <div className='about1'>
          <h1>What We Offer</h1>
          <div className='about11'>
            <Box className='about-box'  p={2} m={1} boxShadow={3} borderRadius={4}>
              <span style={{ fontWeight: 600, fontSize: 'larger' }}>Tailored Loans:</span>
              <p>&nbsp;We understand that every business is unique. That’s why we offer customized loan solutions designed to meet the specific needs of your SME, ensuring you have the financial resources to achieve your goals.</p>
            </Box>
          </div>
          <div className='about11'>
            <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4}>
              <span style={{ fontWeight: 600, fontSize: 'larger' }}>Investment Opportunities:</span>
              <p>&nbsp;At Rain Pe, we provide smart investment opportunities that allow businesses to grow and expand. Our team of experts works closely with you to identify the best investment options to maximize your returns.</p>
            </Box>
          </div>
          <div className='about11'>
            <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4}>
              <span style={{ fontWeight: 600, fontSize: 'larger' }}>Private Equity Consultancy:</span>
              <p>&nbsp;Our private equity consultancy services are designed to help businesses navigate the complex world of private equity. From strategic planning to execution, we offer comprehensive support to ensure your business's long-term success.</p>
            </Box>
          </div>
        </div>
      </div> */}

<div className='about'>
  <div className='about1'>
    <h1>What We Offer</h1>
    <div className='about11'>
      <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} >
        <span style={{ fontWeight: 600, fontSize: 'larger' }}>Tailored Loans:</span>
        <p>&nbsp;We understand that every business is unique. That’s why we offer customized loan solutions designed to meet the specific needs of your SME, ensuring you have the financial resources to achieve your goals.</p>
      </Box>
    </div>
    <div className='about11'>
      <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} ml={2}>
        <span style={{ fontWeight: 600, fontSize: 'larger' }}>Investment Opportunities:</span>
        <p>&nbsp;At Rain Pe, we provide smart investment opportunities that allow businesses to grow and expand. Our team of experts works closely with you to identify the best investment options to maximize your returns.</p>
      </Box>
    </div>
    <div className='about11'>
      <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} ml={2}>
        <span style={{ fontWeight: 600, fontSize: 'larger' }}>Private Equity Consultancy:</span>
        <p>&nbsp;Our private equity consultancy services are designed to help businesses navigate the complex world of private equity. From strategic planning to execution, we offer comprehensive support to ensure your business's long-term success.</p>
      </Box>
    </div>
  </div>
</div>
<div className='about'>
  <div className='about1'>
    <h1>How We Work</h1>
    <div className='about11'>
      <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} >
        <span style={{ fontWeight: 600, fontSize: 'larger' }}>Personalized Consultation</span>
        <p>&nbsp; We begin by understanding your business needs and challenges, ensuring our solutions are tailored to your specific requirements.</p>
      </Box>
    </div>
    <div className='about11'>
      <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} ml={2}>
        <span style={{ fontWeight: 600, fontSize: 'larger' }}>Customized Financial Solutions</span>
        <p>&nbsp; Our experts design financial strategies that align with your goals, providing you with the tools to succeed.</p>
      </Box>
    </div>
    <div className='about11'>
      <Box className='about-box' p={2} m={1} boxShadow={3} borderRadius={4} ml={2}>
        <span style={{ fontWeight: 600, fontSize: 'larger' }}>Ongoing Support and Guidance </span>
        <p>&nbsp;We offer continuous support and advice to help you navigate the financial landscape and achieve sustainable growth.</p>
      </Box>
    </div>
  </div>
</div>

        <Footer />
        </>
    )
}

       

    