import React from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import hbf from "../../assets/images/hbf.png";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
// import nse from '../../assets/images/i2.jpeg'
const ShareCard = ({ imageUrl, name, ipoYear,id,onClick,heading,url }) => {

  return (
    <div>
      <Card sx={{ display: "flex",boxShadow:"2px 2px lightgrey", backgroundColor:'#F4F9F9', padding:'7px',width:350,height:130}}>
        <CardMedia 
          component="img"
          sx={{ width: 100,height:50, margin: "1.5rem 0rem 0rem 0rem",objectFit: 'contain' }}
          image={imageUrl}
          alt={id}
        />
        <CardContent>
         {url !== null  ?  <Typography variant="h8" component="div">
            <Link className="mainbodycard1"  target="_blank" href={url}  onClick={onClick} heading={heading}>{name.toUpperCase()}</Link>
          </Typography> :  <Typography variant="h8" component="div">
            <Link className="mainbodycard1" to="#"  onClick={onClick} heading={heading}>{name.toUpperCase()}</Link>
          </Typography>}
          <Typography variant="body2" color="text.secondary" sx={{paddingTop:'4px', fontFamily:'Daikon-Regular'}}>
            Assumed IPO Year: {ipoYear}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default ShareCard;
