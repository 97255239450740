// import * as React from 'react';
// import Box from '@mui/material/Box';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import Rating from '@mui/material/Rating';
// import './Testimonial.css';

// export default function Testimonial() {
//   const [value, setValue] = React.useState(5);
//   return (

//     <React.Fragment>
//       <Box 
//       style={{ backgroundColor: "#05053a", padding:'1rem 2rem 1rem 2rem',marginTop:"2rem" }}
//       >
//         <Box sx={{ fontSize: "xxx-large", textAlign:'center', fontWeight: 700, color: "white", fontFamily:'Daikon-regular' }}>Testimonials</Box>

//         <Box sx={{ marginTop: "2rem" }}>
//           <Box sx={{ display: "flex", flexDirection: "row" }}>
//             <Box sx={{ width: "50rem", height: "17rem", backgroundColor: "white", margin: "1rem", borderRadius: "15px" }}>
//               <CardContent >
//                 <Typography variant="h6" component="div">
//                 Effective PE investment risk mitigation
//                 </Typography>
//                 <Box sx={{
//                   '& > legend': { mt: 2 },
//                 }}>
//                   <Rating name="read-only" value={value} readOnly />
//                 </Box>
//                 <Typography sx={{ mb: 1.5, fontSize: "0.9rem" }} color="text.secondary">
//                   "I am a CFA candidate, and I realised the risks and complications associated with the private equity market in India. I am happy and surprised to see the risk mitigation techniques and smooth processes of PE investments at RainPe"
//                 </Typography>
//                 <Box sx={{ display: "flex" }}>
//                   <Typography variant="body2" sx={{ fontSize: "medium", fontWeight: 600, padding: "1.5rem 0.3rem 0rem 0rem" }}>
//                   Kartikeya Aggarwal
//                   </Typography>
//                   {/* <Typography sx={{ color: "darkgray", padding: "1.5em 1em 0em 0em" }}>(Long Term Investor)</Typography> */}
//                 </Box>
//               </CardContent>
//             </Box>
//             <Box sx={{ width: "50rem", height: "17rem", backgroundColor: "white", margin: "1rem", borderRadius: "15px" }}>
//               <CardContent>
//                 <Typography variant="h6" component="div">
//                 Ensured fair valuation, avoiding bubble
//                 </Typography>
//                 <Box sx={{
//                   '& > legend': { mt: 2 },
//                 }}>
//                   <Rating name="read-only" value={value} readOnly />
//                 </Box>
//                 <Typography sx={{ fontSize: "0.9rem" }} color="text.secondary">
//                   "In private equity valuation is a bubble and Anuj Ji's team took good care of fair valuation in their products.. "
//                 </Typography>
//                 <Box sx={{ display: "flex", paddingTop: "1.5em", margin: "3.2rem 0rem 0rem 0rem" }}>
//                   <Typography variant="body2" sx={{ fontSize: "medium", fontWeight: 600 }}>
//                   Raj Thakur
//                   </Typography>
//                   {/* <Typography sx={{ color: "darkgray" }}>(Equity Investor)</Typography> */}
//                 </Box>
//               </CardContent>
//             </Box >
//             <Box sx={{ width: "50rem", height: "17rem", backgroundColor: "white", margin: "1rem", borderRadius: "15px" }}>
//               <CardContent>
//                 <Typography variant="h6" component="div">
//                 Impressed by RainPe team's expertise
//                 </Typography>
//                 <Box sx={{
//                   '& > legend': { mt: 2 },
//                 }}>
//                   <Rating name="read-only" value={value} readOnly />
//                 </Box>
//                 <Typography sx={{ mb: 1.5, fontSize: "0.9rem" }} color="text.secondary">
//                   "Interacted with the team at an event in Delhi, these guys have a great understanding of the numbers and the needs of the customer. Looking forward for the meeting, as discussed with Me Nikhil, RainPe.  "
//                 </Typography>
//                 <Box sx={{ display: "flex" }}>
//                   <Typography variant="body2" sx={{ fontSize: "medium", fontWeight: 600, padding: "2.8rem 0.3rem 0rem 0rem" }}>
//                   Mayank Kumar
//                   </Typography>
//                   {/* <Typography sx={{ color: "darkgray", padding: "1.5rem 1rem 0rem 0rem" }}>(Ace Investor)</Typography> */}
//                 </Box>
//               </CardContent>
//             </Box>
//           </Box>
//         </Box>
      
//       </Box>
//     </React.Fragment>
//   );
// }







import * as React from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import './Testimonial.css';

export default function Testimonial() {
  const [value, setValue] = React.useState(5);
  
  return (
    <React.Fragment>
      <Box className="toptestimonial">
        <Box className="testimonial-title">Testimonials</Box>
        <Box 
        className="testimonial-container"
        >
          <Box className="testimonial-box">
            <CardContent>
              <Typography  component="div" className='testimonial-heading' style={{fontWeight:"600"}}>
                Effective PE investment risk mitigation
              </Typography>
              <Box sx={{ '& > legend': { mt: 2 } }}>
                <Rating name="read-only" value={value} readOnly />
              </Box>
              <Typography className="testimonial-text">
                "I am a CFA candidate, and I realised the risks and complications associated with the private equity market in India. I am happy and surprised to see the risk mitigation techniques and smooth processes of PE investments at RainPe"
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Typography className="testimonial-author">
                  Kartikeya Aggarwal
                </Typography>
              </Box>
            </CardContent>
          </Box>
          
          <Box className="testimonial-box">
            <CardContent>
              <Typography component="div" className='testimonial-heading' style={{fontWeight:"600"}}>
                Ensured fair valuation, avoiding bubble
              </Typography>
              <Box sx={{ '& > legend': { mt: 2 } }}>
                <Rating name="read-only" value={value} readOnly />
              </Box>
              <Typography className="testimonial-text">
                "In private equity valuation is a bubble and Anuj Ji's team took good care of fair valuation in their products.. "
              </Typography>
              <Box >
                <Typography className="testimonial-author1"
                //  sx={{margin:'4rem 18rem 0rem 0rem'}}
                 >
                  Raj Thakur
                </Typography>
              </Box>
            </CardContent>
          </Box>
          
          <Box className="testimonial-box">
            <CardContent>
              <Typography className='testimonial-heading' style={{fontWeight:"600"}}>
                Impressed by RainPe team's expertise
              </Typography>
              <Box sx={{ '& > legend': { mt: 2 } }}>
                <Rating name="read-only" value={value} readOnly />
              </Box>
              <Typography className="testimonial-text">
                "Interacted with the team at an event in Delhi, these guys have a great understanding of the numbers and the needs of the customer. Looking forward for the meeting, as discussed with Me Nikhil, RainPe."
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Typography className="testimonial-author2" >
                  Mayank Kumar
                </Typography>
              </Box>
            </CardContent>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

