


import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer.js";
import Navbar from "../../components/navbar/index.jsx";
import './Unlisted.css';
import build from './sp.jfif';
import lup from './lp.png';

import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { api, imageApi } from "../../components/Api/Api.jsx";
import ShareCard from "../../components/cards/ShareCard.jsx";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Unlisted({ idpoly, headings, images, url }) {
  const [data1, setData1] = useState([]);
  const [search, setSearch] = useState("");

  const fetchData = async () => {
    try {
      await fetch(api)
        .then((res) => res.json())
        .then((res) => setData1(res.detail.rows.slice(-9)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const filter =
    data1 !== null
      ? data1.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))
      : [];

  return (
    <>
      <Box>
        <Navbar />
        <Container>
          <Typography sx={{ textAlign: "center", margin: "4rem 0rem 0rem 0rem" }}>
            <h1 style={{ fontSize: "2.5rem", fontFamily: "Daikon-regular" }}>
              Limited Partnerships
            </h1>
          </Typography>
          <p>
          Join the Rain Pe network and collaborate with a leading fintech company dedicated to supporting SMEs. We offer partnership opportunities for limited partners and strategic alliances, enabling you to leverage our expertise and resources for mutual growth and success.
          </p>

          <div className="mediacover1">
  <div className="image-container">
    <img src={lup} className="article-image" alt='articleimage' />
  </div>
  <div className="mediacover11">
    <h1>Limited Partners</h1>
    <p>As a limited partner, you will have the opportunity to invest in high-potential SMEs, gaining access to lucrative investment opportunities and contributing to the growth of emerging businesses. Our partnership provides a platform for strategic investment and long-term value creation.</p>
    <h3>Benefits</h3>
    <div className='letsbuild11'>
      <p>•	Access to High-Potential Investments: Invest in promising SMEs with significant growth potential.




</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Strategic Investment Opportunities: Partner with businesses that align with your investment goals.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Expert Financial Management: Benefit from Rain Pe’s expertise in managing and growing investments.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Transparent Reporting: Receive regular updates and comprehensive reports on your investments.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Networking Opportunities: Connect with other investors and industry leaders.</p>
    </div>
   
    <Container>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button variant="contained" color="primary">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contactus"
                >
                  Contact Us
                </Link>
              </Button>
            </Box>
          </Box>
        </Container>
  </div>
</div>

<div className="mediacover1">

  <div className="mediacover11">
    <h1>Strategic Allainces</h1>
    <p>Partner with Rain Pe to create synergies and drive mutual growth in the SME sector. Our strategic alliances are designed to foster collaboration, innovation, and shared success, allowing us to combine resources and expertise for greater impact.</p>
    <h3>Benefits</h3>
    <div className='letsbuild11'>
      <p>	•	Collaborative Growth: Work together to achieve common goals and drive business growth.









</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Resource Sharing: Leverage each other’s strengths and capabilities for mutual benefit.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Innovation and Development: Partner on innovative projects and initiatives.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Market Expansion: Expand your market reach through strategic partnerships.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Joint Marketing and Branding: Enhance brand visibility and market presence through joint efforts.</p>
    </div>
   <Container>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button variant="contained" color="primary">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contactus"
                >
                  Contact Us
                </Link>
              </Button>
            </Box>
          </Box>
        </Container>
  </div>
  <div className="image-container">
    <img src={build} className="article-image" alt='articleimage' />
  </div>
</div>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button variant="contained" color="primary">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contactus"
                >
                  Sign In Here
                </Link>
              </Button>
            </Box>
          </Box>
        </Container>

        <Box sx={{ marginLeft: "6%", marginRight: "6%", marginTop: "3%" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid container spacing={2}>
              {filter !== null
                ? filter.map((company) => (
                    <Grid item xs={12} sm={6} md={4} key={company.id}>
                      <ShareCard
                        imageUrl={`${imageApi}${company.cmpImg}`}
                        onClick={() => {
                          headings(company.name);
                          idpoly(company.id);
                          images(`${imageApi}${company.cmpImg}`);
                        }}
                        name={company.name}
                        ipoYear={company.ipoYear}
                        url={company.websiteUrl}
                      />
                    </Grid>
                  ))
                : ""}
            </Grid>
          </Grid>
        </Box>
        {filter.length > 0 && (
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <Button variant="contained" color="primary">
              <Link style={{ textDecoration: "none", color: "white" }} to="/companylist">
                View More
              </Link>
            </Button>
          </Box>
        )}
        <Footer />
      </Box>
    </>
  );
}
