import React from "react";
import "./Footer.css";
import badge from "./badge.png";
import apple from "./apple.png";
import aqt from "../../assets/images/rainpe.png";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  return (
    <>
      <div className="mainFooter">
        <div className="blocks">
          <div className="block1">
            <img src={aqt} alt="footer" />
            <p>
              RAIN PE is a dedicated fintech platform empowering SMEs with
              comprehensive financial solutions, including investor funding,
              loans, private equity, project funding, and investment advisory
              services. Our mission is to facilitate growth and success through
              tailored financial strategies. <br />
              <br />* Please note that all services provided are subject to
              terms and conditions, and all decisions should be made after
              careful consideration.
            </p>
          </div>
          <div className="block4">
            <div>
              <h1 className="anchorfoot1">Our Offices</h1>
              <p className="pfooter">
                <LocationOnIcon />
                &nbsp; 2nd Floor ,Highway Tower 1 ,<br class="remove-br" />
                &nbsp;Sector 62 Noida ,<br class="remove-br" />
                &nbsp;Uttar Pradesh 201301
              </p>

              <p className="pfooter" id="footmail">
                <EmailIcon />
                &nbsp;info.rainpe@gmail.com
              </p>
              <div className="iconfooter">
                <FacebookRoundedIcon className="icon" />
                <XIcon className="icon" />
                <LinkedInIcon className="icon" />
                <TelegramIcon className="icon" />
                <YouTubeIcon className="icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="botfoot">©2018-2024 RainPe. All Rights Reserved.</div>
      </div>
    </>
  );
}
