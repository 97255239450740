import './RaiseFunding.css';
import raisefunding from './Raisefunding.png';
import build from './build.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import raising from './raising.png';
import raisingsme from './raisingsme.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import archit from './archit.png';
import sambhav from './sambhv.png';
import insolare from './insolare.png';
import gre from './gre.png';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import madhur from './madhur.jpeg';
import tbiCorn from './tbiCorn.png';
import AccordionRaiseFunding from './Accordian';
import vision from "../../assets/images/funding.avif";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';


// const cardData = [
//     {
//         image: build,
//         title: "Debt Funding (Loans)",
//         content: "Our tailored loan solutions provide the financial support your business needs to thrive. Whether you need working capital, equipment financing, or expansion funds, we offer flexible loan options to meet your specific requirements."
//     },
//     {
//         image: raising,
//         title: "Project Funding",
//         content: "Secure the funding necessary to bring your projects to life. Our project funding options are designed to support a wide range of business initiatives, from research and development to large-scale expansions."
//     },
//     {
//         image: raisingsme,
//         title: "Equity Funding",
//         content: "Gain access to equity financing that can help your business grow without the burden of debt. Our equity funding options are tailored to support businesses looking for strategic investment partners."
//     },
//     {
//         image: raising,
//         title: "IPO Funding",
//         content: "Prepare your business for its Initial Public Offering (IPO) with our expert funding solutions. We provide the financial support and strategic advice you need to make your IPO a success."
//     },
   
// ];


export default function RaiseFunding(){
    return(
        <>
        <Navbar/>
        {/* <div className='decktop'>
        <h1>Help Companies With Deck To Raise Funds</h1>
        <p>"Partnering With The Best Founders In Their Startup Journey At Every Stage." <br/>Welcome to Rain Pe's Services. We offer a range of financial solutions tailored to meet the diverse needs of small and medium-sized enterprises (SMEs). Explore our services and discover how we can help your business thrive.</p> <br/>
        <p> </p>
        <img src={raisefunding} alt='raisefundingimage'/>
        </div> */}
     <div className="main1">
                <div className="main11">

                    <h1>
                    Help Companies With Deck To Raise Funds
                    </h1>
                  
                    <p>Welcome to Rain Pe's Services. We offer a range of financial solutions tailored to meet the diverse needs of small and medium-sized enterprises (SMEs). Explore our services and discover how we can help your business thrive.</p>
                </div>
                {/* <img src={vision} style={{ width: '35%' }} alt='articalimage' /> */}
                <img
    src={vision}
 
    alt="article image"
  />
            </div>
        {/* <Container>
<div className="raisefund">
  {cardData.map((card, index) => (
    <Card key={index} className="raisefundpage">
      <CardActionArea>
        <CardMedia
          component="img"
          image={card.image}
          alt="Card Image"
        />
        <CardContent>
          <Box sx={{ textDecoration: "none" }}>
            <div className='cardendtitle'>
              {card.title}
            </div>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ cursor: "text", fontFamily: 'Daikon-regular' }}>
            {card.content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  ))}
</div>
</Container> */}

{/* <div className="mediacover1">
  <img src={build} style={{ width: '35%' }} alt='articalimage' />
  <div className="mediacover11">
  <h1>
  Debt Funding (Loans)
                    </h1>
                  
                    <p>
Unlock the capital you need to grow your business with our flexible loan solutions designed specifically for SMEs.
 </p>
    <h3>Benefits</h3>
    <div className='letsbuild11'>
     
      <p>•	Tailored Loan Solutions: Customized to meet your business needs.


</p>
    </div>
    <div className='letsbuild11'>
    
      <p>•	Flexible Repayment Terms: Structured to align with your cash flow.</p>
    </div>
    <div className='letsbuild11'>
    
      <p>	•	Competitive Interest Rates: Affordable borrowing with transparent pricing.</p>
    </div>
    <div className='letsbuild11'>
      
      <p>•	Fast and Easy Application Process: Quick and straightforward with minimal paperwork.</p>
    </div>
    <div className='letsbuild11'>
    
      <p>•	Expert Financial Advice: Personalized guidance throughout the loan process.</p>
    </div>
    <h3>Types of Projects Funded</h3>
    <div className='letsbuild11'>
     
      <p>•	R&D Projects: Innovate and develop new products or services.
</p>
    </div>
    <div className='letsbuild11'>
   
      <p>•	Expansion Projects: Grow your business footprint and market reach.</p>
    </div>
    <div className='letsbuild11'>
      
      <p>	•	Infrastructure Projects: Upgrade and enhance your business facilities.</p>
    </div>
    <div className='letsbuild11'>
      
      <p>•	Technology Projects: Invest in new technology and digital transformation..</p>
    </div>
    <div className='letsbuild11'>
    
      <p>•	Marketing Projects: Boost your brand presence and customer engagement.</p>
    </div>
  </div>
</div> */}
<div className="mediacover1">
  <div className="image-container">
    <img src={build} className="article-image" alt='articleimage' />
  </div>
  <div className="mediacover11">
    <h1>Debt Funding (Loans)</h1>
    <p>Unlock the capital you need to grow your business with our flexible loan solutions designed specifically for SMEs.</p>
    <h3>Benefits</h3>
    <div className='letsbuild11'>
      <p>• Tailored Loan Solutions: Customized to meet your business needs.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Flexible Repayment Terms: Structured to align with your cash flow.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Competitive Interest Rates: Affordable borrowing with transparent pricing.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Fast and Easy Application Process: Quick and straightforward with minimal paperwork.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Expert Financial Advice: Personalized guidance throughout the loan process.</p>
    </div>
    <h3>Types of Loans</h3>
    <div className='letsbuild11'>
      <p>• 	Working Capital Loans: Maintain smooth operations and manage day-to-day expenses.

</p>
    </div>
    <div className='letsbuild11'>
      <p>• Equipment Financing: Invest in new equipment or upgrade existing machinery.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Expansion Loans: Capital for opening new locations or increasing production capacity.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Bridge Loans: Temporary funding solutions for short-term financial needs.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Invoice Financing: Turn unpaid invoices into immediate cash flow.</p>
    </div>
    <Container>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button variant="contained" color="primary">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contactus"
                >
                  Apply Now
                </Link>
              </Button>
            </Box>
          </Box>
        </Container>
  </div>
</div>
<div className="mediacover1">
 
  <div className="mediacover11">
    <h1>IPO Funding</h1>
    <p>
 Prepare for a successful Initial Public Offering (IPO) with our comprehensive financial support and strategic advice.
</p>
    <h3>Benefits</h3>
    <div className='letsbuild11'>
      <p>• 	Comprehensive Support: From pre-IPO planning to post-IPO execution.




</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Expert Advice: Guidance from experienced professionals with a track record of successful IPOs.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Customized Solutions: Tailored financial strategies to meet your business needs.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Risk Management: Structured to minimize financial risks during the IPO process.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Growth Capital: Access to public markets for long-term funding.</p>
    </div>
    <h3>How We Support Your IPO</h3>
    <div className='letsbuild11'>
      <p>
      •	Pre-IPO Planning: Strategic planning and preparation for a successful IPO.

</p>
    </div>
    <div className='letsbuild11'>
      <p>• Financial Structuring: Optimal financial structuring to maximize valuation.</p>
    </div>
    <div className='letsbuild11'>
      <p>•Regulatory Compliance: Ensuring compliance with all regulatory requirements.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Marketing and Roadshows: Support in marketing your IPO to potential investors.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Post-IPO Support: Ongoing support to ensure a smooth transition to public company status.</p>
    </div>
    <Container>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button variant="contained" color="primary">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contactus"
                >
                  Apply Now
                </Link>
              </Button>
            </Box>
          </Box>
        </Container>
  </div>
  <div className="image-container">
    <img src={raising} className="article-image" alt='articleimage' />
  </div>
</div>
<div className="mediacover1">
<div className="image-container">
    <img src={raisingsme} className="article-image" alt='articleimage' />
  </div>
  <div className="mediacover11">
    <h1>Equity Funding</h1>
    <p>
 Gain access to strategic investment partners and grow your business without the burden of debt through our equity funding solutions.
</p>
    <h3>Benefits</h3>
    <div className='letsbuild11'>
      <p>•	Strategic Partnerships: Connect with investors who believe in your business.




</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Growth Capital: Funds to scale your operations and market presence.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Expert Support: Guidance on managing equity investments and shareholder relations.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Flexible Terms: Structured to suit your business's financial needs and goals.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Long-Term Success: Focused on sustainable growth and value creation.</p>
    </div>
    <h3>Types of Equity Investments</h3>
    <div className='letsbuild11'>
      <p>
•	Seed Funding: Early-stage capital to launch your business.
	
</p>
    </div>
    <div className='letsbuild11'>
      <p>• Growth Capital: Funding for scaling operations and entering new markets.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Venture Capital: Investment to support high-growth potential businesses</p>
    </div>
    <div className='letsbuild11'>
      <p>• Private Equity: Long-term investment for established businesses..</p>
    </div>
    <div className='letsbuild11'>
      <p>•Strategic Partnerships: Equity deals with industry partners..</p>
    </div>
    <Container>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button variant="contained" color="primary">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contactus"
                >
                  Apply Now
                </Link>
              </Button>
            </Box>
          </Box>
        </Container>
  </div>

</div>

<div className="mediacover1">
 
  <div className="mediacover11">
    <h1>Project Funding</h1>
    <p>
 Bring your projects to life with our dedicated funding options, supporting everything from research and development to large-scale expansions
</p>
    <h3>Benefits</h3>
    <div className='letsbuild11'>
      <p>•	Customized Funding Solutions: Designed to meet the specific needs of your projects.




</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Comprehensive Support: From initial planning to execution.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Flexible Terms: Funding options that match your project timelines.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Expert Guidance: Strategic advice from experienced professionals.</p>
    </div>
    <div className='letsbuild11'>
      <p>•	Risk Mitigation: Structured to minimize financial risks.</p>
    </div>
    <h3>Types of Projects Funded</h3>
    <div className='letsbuild11'>
      <p>• R&D Projects: Innovate and develop new products or services.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Expansion Projects: Grow your business footprint and market reach.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Infrastructure Projects: Upgrade and enhance your business facilities.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Technology Projects: Invest in new technology and digital transformation.</p>
    </div>
    <div className='letsbuild11'>
      <p>• Marketing Projects: Boost your brand presence and customer engagement.</p>
    </div>
    <Container>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button variant="contained" color="primary">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contactus"
                >
                  Apply Now
                </Link>
              </Button>
            </Box>
          </Box>
        </Container>
  </div>
  <div className="image-container">
    <img src={raising} className="article-image" alt='articleimage' />
  </div>
</div>


     <br/>
                <Footer/>
        </>
    )
}