

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import refresh from './refresh.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import IT from '../../../assets/images/IT.jpg';
import Finance from '../../../assets/images/Finance.jpg';
import Health from '../../../assets/images/Health.jpg';
import Sales from '../../../assets/images/Sales.jpg';
import Media from '../../../assets/images/Media.jpg';
import Marketing from '../../../assets/images/Marketing.jpg';
import Stocks from '../../../assets/images/Stocks.jpg';
import Data from '../../../assets/images/Data.jpg';
import Buisness from '../../../assets/images/Buisness.jpg';
import Agriculture from '../../../assets/images/Agriculture.png';
import '../MainBody.css';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BodyInside() {
  return (
    <>
      <div className='bodyinsidetop'>
        <Typography sx={{ textAlign: 'center', fontFamily: 'Daikon-regular' }}></Typography>
       
        
        <Typography variant="h5" sx={{textAlign:"center",fontWeight:600,margin:"2rem 0rem 2rem 0rem",fontFamily: "Daikon-regular"}}>Top Sectors In SME Market</Typography>
        <Box sx={{margin:"0.5rem 6rem 0.5rem 6rem",textAlign:"left",fontFamily: "Daikon-regular"}}>
        <Typography variant="p" sx={{fontFamily: "Daikon-regular"}} >  We invite you to join the Rain Pe community and experience the difference our financial solutions can make for your business. Whether you're looking for a tailored loan, strategic investment, or expert consultancy, Rain Pe is here to help you succeed.</Typography>
        </Box>
        <br/>
        <Container>
          <OwlCarousel 
            className='owl-theme' 
            loop={true} 
            autoplay={true} 
            margin={10} 
            autoplayTimeout={3000} 
            items={1} 
            nav={false} 
            responsive={{
              0: { items: 1 },
              600: { items: 2 },
              960: { items: 3 },
              1200: { items: 4 },
              1400: { items: 5 }
            }} 
            style={{ textAlign: 'center' }}
          >
            <div className='item'>
              <img className='carouselDiv' src={Agriculture} alt='agri' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Agriculture</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={IT} alt='it' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>IT</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={Finance} alt='finance' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Finance</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={Health} alt='health' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Health</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={Sales} alt='sales' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Sales</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={Media} alt='media' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Media</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={Marketing} alt='market' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Marketing</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={Stocks} alt='stocks' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Stocks</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={Data} alt='data' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Data</Typography>
            </div>
            <div className='item'>
              <img className='carouselDiv' src={Buisness} alt='business' style={{ width: '100%', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: 'green', cursor: 'pointer' } }}>Buisness</Typography>
            </div>
          </OwlCarousel>
        </Container>
        <div style={{ paddingBottom: '20px', marginTop: '18px' }}></div>
      </div>
    </>
  );
}




