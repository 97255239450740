import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import terms from '../../assets/images/terms.jpg';
import Navbar from '../../components/navbar/index';
import Footer from '../../components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


export default function Terms(){
    const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return(
        <>
        <Navbar/>
        <Box>
        <Typography variant='h3' sx={{fontWeight:600,textAlign:'center', fontFamily:'Daikon-regular'}}>Terms Of Use</Typography>
        <Typography  sx={{textAlign:'center',fontSize:"x-large",fontFamily:'Daikon-regular'}}>Please check the details on this page carefully</Typography>
        </Box>
        <img style={{width:"97.6%",margin:"2rem 1rem 1rem 1rem",borderRadius:"15px"}} src={terms} alt='terms'/>
        <Box sx={{margin:"1rem 8rem 1rem 8rem", fontFamily:'Daikon-regular'}}>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem",textAlign:"justify",fontFamily:'Daikon-regular'}}>This web page or document is an electronic record in terms of the Information Technology Act, 2000 and the rules framed thereunder. This electronic record is generated by a computer system and does not require any physical or digital signatures.</Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem",textAlign:"justify",fontFamily:'Daikon-regular'}}>This web page or document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and terms of use for access or usage of the website www.unlistedzone.com.</Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem",textAlign:"justify",fontFamily:'Daikon-regular'}}>IZUZ Consultancy Pvt. Limited. and Investor Zone and Unlisted Zone are private limited company and partnership firm having its registered office at A-162, New Panchwaty Colony, Ghaziabad, Uttar Pradesh-201001 and 232/12, New Kot Gaon, Near Shiv Mandir, Ghaziabad, Uttar Pradesh-201001, respectively. (hereinafter referred to as “We”, “Us”, “IZUZ Consultancy Pvt. Limited. and Investor Zone and Unlisted Zone” or “Our” which expression shall mean and include its affiliates, successors and permitted assigns). Your (“You” or “Your”) use of the website  (defined below) is subject to the terms and conditions set forth in these Terms of Use. The domain name www.unlistedzone.com (hereinafter collectively referred to as the “website”) is owned by IZUZ Consultancy Pvt. Limited and Investor Zone and Unlisted Zone partnership firm.</Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem",textAlign:"justify",fontFamily:'Daikon-regular'}}>Your use of the website is governed by the following terms and conditions (“Terms of Use”) as applicable to the website including the applicable policies which are incorporated herein by way of reference. You acknowledge and agree that you shall be subject to the policies that are applicable to the website and by mere use of the website, you shall be contracting with IZUZ Consultancy Pvt. Limited. or Investor Zone and Unlisted Zone and these terms and conditions including the policies constitute your binding obligations, with IZUZ Consultancy Pvt. Limited. and Investor Zone and Unlisted Zone. By mere use of the website, you agree to be subject to the applicable rules,  guidelines, policies, terms, and conditions and the same shall be deemed to be incorporated into this Terms of Use and be considered as part and parcel of this Terms of Use. We reserve the rights, at our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to you. It is your responsibility to review these Terms of Use periodically for updates or any changes. We will notify you of any material updates or changes from time to time. Your continued use of the website following the posting of changes will mean that you accept and agree to the revisions. As long as you comply with these Terms of Use, we grant you a personal, non- exclusive, non-transferable, limited privilege to enter and use the website. </Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem",textAlign:"justify",fontFamily:'Daikon-regular'}}>These Terms of Use will be effective immediately upon your acceptance of our terms and conditions, Your use of our website being indicative of such acceptance. These Terms of Use shall be enforceable against you in the same manner as any other written agreement.</Typography>
            <Typography variant='p' sx={{margin:"1rem 0rem 1rem 0rem",textAlign:"justify",fontFamily:'Daikon-regular'}}>What We Do: We provide a service  through UnlistedZone.com website wherein the investors after registering can search for Private Equity and after proper due diligence, if interested, leave their acceptance/request through email to buy or sell a particular share. UnlistedZone team when finds a seller, will contact you and complete the deal. Registered users are hereinafter referred to as Members. We are enabling buyers to find investment opportunity and letting sellers to sell their shares in case they need to liquidate their Private Equity.</Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem",textAlign:"justify",fontWeight:600,fontFamily:'Daikon-regular'}}>RainPe Website:</Typography>
            <Box sx={{margin:"1rem 0rem 1rem 0rem",display:"flex"}}><Typography sx={{fontWeight:600,fontFamily:'Daikon-regular'}}>2.1:</Typography> <Typography sx={{textAlign:"justify",fontFamily:'Daikon-regular'}}> You can access the website  only after registering for an account. Your profile must be your own or of a person or entity You are entitled to represent and You shall not use or provide any information that is offensive or that</Typography></Box>
            <Box sx={{margin:"1rem 0rem 1rem 0rem",display:"flex"}}><Typography sx={{fontWeight:600}}>2.2: </Typography><Typography sx={{textAlign:"justify",fontFamily:'Daikon-regular'}}> You can choose to de-activate Your account with UnlistedZone.com maintained on the website by dropping an email to our support team at sales@unlistedzone.com</Typography> </Box>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem",textAlign:"justify",fontFamily:'Daikon-regular'}}>If you have any questions or queries related to our terms of use i,e, this page then please reach out to us at +919191919191 or drop an e-mail to RainPe@gmail.com</Typography>
        </Box>
        <Footer/>
        </>
    )
}